import React from "react";
import { Secondary } from "../../components/layouts/Secondary/Secondary";
import * as styles from "./signuppromo.module.sass";

const SignupPromo = () => (
  <Secondary>
    <main className={styles.main}>
      <h2>Open an account, get $10. Simple. </h2>
      <h2>
        <strong>How can you get your $10? Easy.</strong>
      </h2>
      <p>Just download the app.</p>
      <p>Open an account by January 31, 2023.</p>
      <p>Get $10.</p>

      <h2>
        <strong>Who is eligible?</strong>
      </h2>
      <p>To be eligible you must be:</p>
      <ul>
        <li>A new Flahmingo user</li>
        <li>A legal resident of Alberta</li>
        <li>18 years or older</li>
      </ul>
      <h2>
        <strong>Legal</strong>
      </h2>
      <ol>
        <li>
          Open a NEW Flahmingo trading account (“New Account”) on or by January
          31, 2023. Existing account holders do not qualify for this offer.
        </li>
        <li>
          The cash award will be paid in Canadian dollars to the eligible user
          account on account opening.
        </li>
        <li>The maximum total cash award per user is $10.</li>
        <li>
          A user must maintain a minimum balance of $10 in total assets (cash
          and equities) until June 30, 2023, excluding the impact of declining
          equity value. For example, if a user has not funded their account and
          purchases stocks with the $10 cash award, and the value of those
          stocks decreases putting the user below the minimum balance of $10,
          the user would still be considered to be within the minimum balance
          threshold.
        </li>
        <li>
          A user must keep the New Account open with Flahmingo until June 30,
          2023.
        </li>
        <li>
          There may be tax implications associated with the cash award. Users
          should consult with their personal tax advisor for more information.
          The cash award is paid directly to the user’s Tax Free Savings Account
          and is not considered a contribution.
        </li>
        <li>
          Employees of Flahmingo and their household are not eligible for this
          offer.
        </li>
        <li>The offer is not transferable.</li>
        <li>
          For the users participating in this offer, this offer cannot be used
          in conjunction with any other offer.
        </li>
        <li>
          This offer may be changed, extended or withdrawn at any time without
          notice.
        </li>
      </ol>
    </main>
  </Secondary>
);
export default SignupPromo;
